import { createApp } from 'vue'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import './assets/custom.scss';

import App from './App.vue'
import router from './router'
import { store } from './store'
import { BootstrapVue3 } from 'bootstrap-vue-3';


const app = createApp(App)
.use(store)
.use(router)
.use(BootstrapVue3);

app.config.errorHandler = (err, vm, info) => {
    console.error("TEST - Error:", err);
    console.error("TEST - Vue component:", vm);
    console.error("TEST - Additional info:", info);
};

app.config.warnHandler = (err, vm, info) => {
    console.warn("TEST WARNING - Error:", err);
    console.warn("TEST WARNING - Vue component:", vm);
    console.warn("TEST WARNING - Additional info:", info);
};

app.mount('#app');
